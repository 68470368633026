@import "https://visitsofia.webnovel.bg/_public/plugins/ci/ci.css";
@import "ui.css";
:root {
    --route-map-height: 600px;
    --popup-image-height: 250px;
}
.custom-waypoint-icon {
    background: var(--main);
    color: #fff;
    line-height: 36px;
    text-align: center;
    border-radius: 100%;
    box-shadow: 0 0 5px rgba(0,0,0,0.9);
    transition: 0.2s;
    font-size: 12px;
}

.custom-waypoint-icon .ci {
    width: 20px;
    height: 20px;
    background: #ffffff;
    transform: translateY(3px);
}

.custom-waypoint-icon.selected .dark .ci {
    background: var(--main);
}

.custom-waypoint-icon .map-number {
    display: none;
}

.custom-waypoint-icon .map-icon {
    display: block;
}

.custom-waypoint-icon:hover .map-number {
    display: block;
}
.custom-waypoint-icon:hover .map-icon {
    display: none;
}

.custom-waypoint-icon.object:hover .map-icon {
    display: block;
}

.custom-waypoint-icon:hover {
    background: var(--main-active);
}

.custom-waypoint-icon.selected {
    color: var(--main);
    background: #fff;
    border: 4px solid var(--main);

    font-size: 16px;
    z-index: 10000000000;
}

.custom-waypoint-icon.object.selected .ci {
    background: var(--main);
}

.leaflet-routing-container:not(.leaflet-routing-container:first-child) {
    display: none !important;
}

.leaflet-top.leaflet-right {
    display: none !important;
}

.route-map-container {
    width: 100%;
    height: var(--route-map-height);
    transition: 0.5s;
}

.route-map-container.with-object {
    width: calc(100% - 430px);
}


.relative {
    position: relative;
}


.info-box {
    width: 400px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
    transform: translateX(400px);
    height: calc(100% - 75px);
    background: #f1f1f1;
    z-index: 10000;
    padding: 15px;
    box-shadow: -10px 0 7px rgba(0, 0, 0, 0.2);
}

.info-box.visible {
    opacity: 1;
    transform: translateX(0);
}


.img-info-box, .img-info-box .splide {
    width: 100%;

    overflow: hidden;
    display: flex;
    justify-content: center;
}

.info-box .title {
    font-size: 16px;
    line-height: 20px !important;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
}

.info-box .text {
    font-size: 15px;
    font-weight: normal !important;
}
.img-info-box .list-img {
    height: var(--popup-image-height);
}
.img-info-box .list-img img {
    width: auto;
    height: 100%;
}

.info-box .close-info-box {
    line-height: 30px;
    width: 30px;
    background: #f1f1f1;
    text-align: center;
    position: absolute;
    top: -30px;
    left: 0;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    transition: 0.3s;
}

.close-info-box:hover {
    background: #d7d7d7;
    color: var(--main-active);
}

.objects-list .element {
    margin-top: 30px !important;
    display: flex !important;
    flex-wrap: wrap;
    background: #f1f1f1;
    border-radius: 8px;
    overflow: hidden;
}

.object-list-img, .object-list-img .splide {
    width: 300px;
    height: auto;
    overflow: hidden !important;
}

.object-list-info {
    width: calc(100% - 340px);
    padding: 15px;
}

.object-list-title {
    font-size: 20px;
    font-weight: bold;
    width: calc(100% - 130px);
}

.object-list-text * {
    font-size: 16px;
}

.object-list-img img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 450px) {
    .info-box { width: calc(100% - 30px)}
}


.nav {
    transform: rotate(45deg);
}

.switch-buttons {
    background: #f1f1f1;
    border: 1px solid #cccccc;
    cursor: pointer;
    margin: 3px;
    border-radius: 4px;
    height: 26px;
    font-size: 16px;
}

.switch-buttons.selected {
    background: var(--main);
    color: white;
    border-color: var(--main-active);
}


.toggle-container {
    width: 100%;
    height: calc(var(--route-map-height) - 80px);
    overflow-y: scroll;
    transition: 0.3s;
    background: #f1f1f1;
}

.toggle-container:hover {
}

.title-button {
    text-align: right;
}
